import React from 'react'
import Layout from '../components/layout'
import './print-page.scss';

class PrintPageTemplate extends React.Component {
    render() {
        const print = this.props.data.markdownRemark
        console.log(print);
        // const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        // const siteDescription = post.excerpt
        // const { previous, next } = this.props.pageContext

        return (
            <Layout>
                <div className="page-layout print-detail-page">
                    <div className="container">
                        <h1>{print.frontmatter.title}</h1>
                        <div className="print-detail-page__data">
                            <img src={print.frontmatter.image.childImageSharp.fluid.src} />
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: print.html }}></div>
                                <a className="button" href="mailto:madelinesipp@gmail.com">Order</a>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default PrintPageTemplate;

export const pageQuery = graphql`
  query PrintBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        # author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        image {
            childImageSharp {
                fluid(maxWidth: 960) {
                    src
                    srcSet
                    sizes
                }
            }
        }
        # date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`